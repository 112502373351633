import * as React from 'react'

// Components
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import DuoSection from '../../components/duoSection'
import Button from '../../components/button'

class ProfileDeleted extends React.Component {
	render() {
		return <Layout className="profile-deleted nav-blue-half no-footer">
			<Seo title="Profile Deleted" />
			<DuoSection ProfileDeleted success>
				<div>
					<div className="content-wrap">
						<h1>Profile<br/>Deleted</h1>
						<Button to="/register" hollow xsBlock colorWhite>Register</Button>
					</div>
				</div>
				<div></div>
			</DuoSection>
		</Layout>
	}
}

export default ProfileDeleted
